<template>
  <div>
    <VTitle :title="$t('app.overview')" class="section-title" />

    <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
      <div v-for="(value, key) in data" :key="key">
        <VTitle :title="$t(`app.${key}`, 2)" class="overview-title" />

        <VStatisticsCard :statistics="value" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import VStatisticsCard from "@/components/VStatisticsCard";

export default {
  components: {
    VTitle,
    VStatisticsCard
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { request } = useRequest();

    // Data
    const data = ref({});
    const isLoading = ref(false);

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.overview")} - ${t("app.instructors", 2)} - ${t("app.teaching")}`;

      const { firstname, lastname } = props.resource ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      const response = await request({
        endpoint: "teaching.instructors.overview",
        pathParams: { id: props.id },
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      data,
      isLoading
    };
  }
};
</script>
